<template>
    <b-overlay :show="showLoader" style="position: relative;">
        <StoreCategories :categories="categories"/>
        <blue-background class="mt-2"/>
        <div class="px-1 pt-5" v-if="current_category" style="background: white;">
            <div class="d-md-flex w-100 d-block">
                <div class="current-category">{{current_category.title}}</div>
                <div class="aktualno-pasica d-flex align-items-center">
                    <div class="aktulano-text">AKTUALNO</div>
                    <div class="line my-auto"/>
                </div>
            </div>
        </div>
        <!--        <div v-if="webShopItems.length > 0" class="pt-1 bg-white">
            <swiper :swiper-data="webShopItems" :pagination="true" type="sale"/>
        </div>-->
        <div class="mt-1 bg-white">
            <b-row class="items">
                <template v-if="webShopItems && webShopItems.length > 0">
                    <b-col v-for="item of webShopItems" :key="item.id" sm="12" md="6" lg="4" class="mb-2 d-flex col-item">
                        <sale-card :object="item"/>
                    </b-col>
                </template>
                <template v-else>
                    <b-col class="text-center">
                        <h3>V tej kategoriji ni oglasov</h3>
                    </b-col>
                </template>
            </b-row>
        </div>
        <pagination v-if="pagination" v-model="pagination" class="mt-1"/>

        <div class="d-flex justify-content-center mt-3" v-if="top_categories">
            <div class="text-center">
                <div style="font-weight: bold; font-size: 25px;">
                    TOP KATEGORIJE
                </div>

                <blue-background class="mt-2" :height="240"/>

                <div class="mt-2 d-flex justify-content-center pb-5 pt-1">
                    <div class="row d-flex justify-content-center">
                        <div v-for="(item,id) of top_categories" :key="'topcategory_' + id" class="col-md-4 col-6 text-center">
                            <div v-if="item">
                                <router-link :to="{name: 'web-shop-category-items-list', params: {'category_id': item.id}}">
                                    <img class="imgTopCategory" :src="'/api/user/v1/image/' + item.main_photo" />
                                    <div class="mt-1">
                                        {{item.title.toUpperCase()}}
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <news-carousel class="mt-2"/>
    </b-overlay>
</template>

<script>
    import {BOverlay, BRow, BCol} from 'bootstrap-vue'
    import BlueBackground from '@/views/Components/BlueBackground'
    //import Swiper from '@/views/Components/Swiper'
    import StoreCategories from '@/views/Components/StoreCategories'
    import Pagination from '@/views/Components/Pagination'
    import saleCard from '@/views/Components/SaleCard'
    import newsCarousel from '@/views/Components/NewsCarousel'
    export default {
        components: {
            BlueBackground,
            BOverlay,
            //Swiper,
            StoreCategories,
            BRow,
            BCol,
            saleCard,
            Pagination,
            newsCarousel
        },
        data() {
            return {
                showLoader: false,
                show: false,
                categories: [],
                webShopItems: [],
                pagination: null,
                articlesPerPage:6,
                perPage: 6,
                currentPage: 1,
                paginationQuery:'',
                created: false,
                mainCategory: null,
                checkType: null,
                windowWidth: window.innerWidth,
                configuration: null,
                current_category: null,
                top_categories: []
            }
        },
        methods: {
            getMatchingCategory(category_id) {
                for (const mainCategory of this.categories) {

                    if (mainCategory.id === category_id) {
                        return mainCategory
                    }

                    for (const childCategory of mainCategory.children) {
                        if (childCategory.id === category_id) {
                            return childCategory
                        }
                    }
                }
            },
            async getConfiguration() {
                try {
                    const res = await this.$http.get('/api/user/v1/webshop/configuration/homepage')
                    this.configuration = res.data

                    this.current_category = this.getMatchingCategory(this.configuration.current_category)
                    this.top_categories = []

                    for (const category of this.configuration.top_categories) {
                        this.top_categories.push(this.getMatchingCategory(category))
                    }

                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.showLoader = false
                }
            },
            async loadAllData() {
                this.showLoader = true
                this.created = false
                try {
                    await this.loadCategories()
                    await this.getConfiguration()
                    await this.loadItems()
                } catch (error) {
                    this.showLoader = false
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.showLoader = false
                    this.created = true
                }
            },
            async loadCategories() {
                const res = await this.$http.get('/api/user/v1/web_shop/category')
                this.categories = res.data
            },
            async loadItems() {
                try {
                    if (!this.current_category || !(this.current_category && this.current_category.bbis_id)) {
                        return
                    }
                    await this.loadArticlesPerPage()

                    let queryParams = ''
                    if (this.paginationQuery !== '') {
                        queryParams = this.paginationQuery
                    } else {
                        queryParams = this.getQueryParams()
                    }

                    const res = await this.$http.get(`/api/user/v1/web_shop/?split_categories.[]=${ this.current_category.bbis_id}&&${queryParams}`)
                    this.webShopItems = res.data.items ?? []
                    this.pagination = res.data.pagination
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                }
            },
            async loadArticlesPerPage() {
                try {
                    const res = await this.$http.get('/api/user/v1/webshop/configuration/homepage')
                    this.articlesPerPage = res.data.number_of_articles_per_page
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                }
            },
            onSelect(id) {
                this.$router.push({name: 'web-shop-category-items-list', params: {'category_id': id}})
            },
            getQueryParams() {

                const page = 1
                const perPage = this.articlesPerPage
                //this.perPage = perPage

                const pageQueryParam = (page ? `&page=${page}&` : '')
                const perPageQueryParam = (perPage ? `&perPage=${perPage}&` : '')

                const p = [
                    pageQueryParam,
                    perPageQueryParam
                ]

                return p.join('')
            }
        },
        watch: {
            async '$route.query'() {

                if (!this.backForwardNavigation) {
                    return
                }

                this.$scrollToTop()
                await this.loadItems()
            },
            'pagination.current_page'() {
                if (!this.created || !this.pagination) return

                //this.$router.push({'path': this.$route.fullPath, 'query': {'page': this.pagination.current_page, 'perPage': this.pagination.per_page = 6}})
                this.pagination.per_page = this.articlesPerPage
                this.paginationQuery = `page=${this.pagination.current_page}&perPage=${this.pagination.per_page}`

                this.loadItems()
            }
        },
        mounted() {
            this.loadAllData()
        }
    }
</script>

<style scoped>
@media only screen and (max-width: 349px) {
    .imgTopCategory{
        width: 110px;
        height: 110px;
        border-radius: 50%;
    }
     .aktualno-pasica{
        width: 80% !important;
        position: relative;
        margin-top: 40px !important;
    }
}
@media only screen and (min-width: 350px) and (max-width: 768px) {
    .imgTopCategory{
        width: 140px;
        height: 140px;
        border-radius: 50%;
    }
    .aktualno-pasica{
        width: 100% !important;
        position: relative;
        margin-top: 40px !important;
    }
}
@media only screen and (min-width: 769px) {
    .imgTopCategory{
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }
    .aktualno-pasica{
        width: 80%;
        position: relative;
        margin-top: 0px;
    }
}
.current-category{
    background: #737373;
    border-radius: 40px;
    padding: 3px 20px;
    color: white;
}
.aktulano-text{
    background: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    font-size: 25px;
}
.line{
    width: 100% !important;
    min-width: 100%;
    height: 2px !important;
    background: black;
}
.carousel-indicators li {
    background-color: black;
}

@media (min-width: 768px) and (max-width: 992px) {
    .items > .col-item:nth-child(odd)::after {
        border-right: 0.031rem solid #009ED3;;
        content:"";
        position:absolute;
        top: 5rem;
        right:0;
        height: 250px;
    }
}
@media only screen and (min-width: 992px) {
    .items > .col-item:nth-child(3n - 1)::after {
        border-right: 0.031rem solid #009ED3;;
        content:"";
        position:absolute;
        top: 5rem;
        right:0;
        height: 250px;
    }
    .items > .col-item:nth-child(3n - 2)::after {
        border-right: 0.031rem solid #009ED3;;
        content:"";
        position:absolute;
        top: 5rem;
        right:0;
        height: 250px;
    }
}
</style>
